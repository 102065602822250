require('./bootstrap');

$(document).ready(function (){
   if($('.JS-payment-back').length > 0){
        setTimeout(function (){
            $(".loader-outer").hide();
            $(".panel-heading").css("background", "#38c172");
            $("h2").html("PŁATNOŚĆ ZOSTAŁA PRZETWORZONA").css('padding', "50px 0");
            $("p").html("Płatność została przetworzona, wróć do aplikacji, aby kontynuować");
            $(".JS-back").show();
        }, 3500);
   }

    $(".JS-select-location").select2({
        minimumInputLength: 3,
        theme: 'bootstrap',
        ajax: {
            url: '/app/get/location',
            dataType: 'json',
            delay: 2050,
            data: function (params) {
                let query = {
                    term: params.term
                }
                return query;
            },
            processResults: function (data) {
                return {
                    results: data
                };
            }
        }
    });
});

$(document).on('blur', '.JS-check-email', function () {
    let value = $(this).val();
    if (value){
        const body = {
            email: $(this).val()
        };

        axios.post('/app/check-valid-email', body).catch(error => {
            if (error.response.status === 409){
                $('#userExistsModal').modal('show');
            }
        })
    }
});

$(document).on('click', '.JS-show-more', function (){
    const target = $(this).data('target');
    console.log($(target));
    $(target).toggleClass('show_more');
});

$(document).on('click', '.JS-show-more-translation', function (){
    if($(this).hasClass('more')){
        $(this).html('Mniej');
        $(this).removeClass('more');
    } else {
        $(this).html('Więcej');
        $(this).addClass('more');
    }
});

$(document).mouseup(function(e) {
    if ($('.JS-nav-dropdown.active').length > 0 && $(e.target).parents('.JS-nav-dropdown').length == 0) {
        $('.JS-nav-dropdown').removeClass('active');
    }
});

$(document).on('click', '.JS-nav-dropdown', function (){
    const hasClass = $(this).hasClass('active');
    $('.JS-nav-dropdown').removeClass('active');
    if (!hasClass){
        $(this).addClass('active');
    }
});

$(document).on('click', '.JS-schedule-toggle', function (){
    $(".schedule__toggle-label, .schedule__toggle-switch", this).toggleClass('active');
    const value = $(".JS-online-checkbox").val();
    $(".JS-online-checkbox").val(parseInt(value) === 1  ? 0 : 1);
});

$(document).on('click', '.JS-show-more-detail', function (){
    const target = $(this).data('target');
    console.log($(target));
    $(target).toggleClass('show_more');
    $(this).remove();
});

$(document).on('click', '.JS-service-search.active', function (){
    $('.JS-service-dropdown').addClass('active');
});

$(document).on('click', '.JS-block:not(.show-services) .JS-show-services', function (){
    $(this).parents('.JS-block').addClass('show-services');
});

$(document).on('click', '.JS-block.show-services .JS-show-services', function (){
    $(this).parents('.JS-block').removeClass('show-services');
});

$(document).on("click", ".JS-service-search:not(.active), .JS-block.show-services .JS-show-services:not(.active)", function () {
    let service = $(this).data('service');
    let vet = $(this).data('vet');
    let days = $(this).data('days') ?? 4;
    let d = new Date();

    $(this).addClass('active').siblings().removeClass('active');
    $('.JS-service-dropdown').removeClass('active');

    getCalendarTimes(service, vet, days, d);

    $(`.JS-calendar-prev[data-vet="${vet}"]`).addClass('disabled');
});

$(document).on("click", ".JS-calendar-next", function () {
    let vet = $(this).data('vet');
    let days = $(this).data('days') ?? 4;
    let service = $(`.JS-service-search[data-vet="${vet}"]`).data('service');
    let parent = $(this).parents('.JS-calendar-nav');

    let offset = parseInt(parent.data('offset'));
    let newOffset = offset + days;
    parent.data('offset', newOffset);

    let now = new Date()
    let d = new Date(now.getFullYear(),now.getMonth(),now.getDate()+newOffset);

    getCalendarTimes(service, vet, days, d);

    $(`.JS-calendar-prev[data-vet="${vet}"]`).removeClass('disabled');
});

$(document).on("click", ".JS-calendar-prev:not(.disabled)", function () {
    let vet = $(this).data('vet');
    let days = $(this).data('days') ?? 4;
    let service = $(`.JS-service-search[data-vet="${vet}"]`).data('service');
    let parent = $(this).parents('.JS-calendar-nav');

    let offset = parseInt(parent.data('offset'));
    let newOffset = offset - days;
    parent.data('offset', newOffset);

    let now = new Date()
    let d = new Date(now.getFullYear(),now.getMonth(),now.getDate()+newOffset);

    getCalendarTimes(service, vet, days, d);
    if (newOffset === 0){
        $(this).addClass('disabled');
    }
});

function getCalendarTimes(service, vet, days, d){
    const month = d.getMonth() + 1;
    const day = d.getDate();

    const date = d.getFullYear() + '/' +
        (month < 10 ? '0' : '') + month + '/' +
        (day < 10 ? '0' : '') + day;

    const data = {
        vet,
        date,
        service,
    };

    axios.post(`/app/vet/times/${days}`, data).then(res => {
        $.each(res.data, (i, v) => {
            let col = $(`#JS-column-${vet}-${i}`);
            let heading = $(`#JS-heading-${vet}-${i}`);
            heading.html(v.heading)
            if(v.times.length === 0){
                col.html(`<div class="calendar__column empty">brak terminów</div>`);
            } else{
                col.empty();
            }

            $.each(v.times, (index, value) => {
                col.append(`<form action="/make-appointment" id="resForm-${i}-${index}-${vet}" method="GET">
                                <input type="hidden" name="vet" value="${vet}">
                                <input type="hidden" name="service" value="${service}">
                                <input type="hidden" name="start_date" value="${value.start_time}">
                                <input type="hidden" name="end_date" value="${value.end_time}">
                            </form>
                            <a class="calendar__hour" onclick="event.preventDefault(); document.getElementById('resForm-${i}-${index}-${vet}').submit();" href="/make-appointment">${value.start_time_formatted}</a>`);
            })
        });
    }).catch(error => {

    });
}
